@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/helpers";
@import "~bootstrap/scss/utilities/api";

$font-family-sans-serif: "Nunito Sans", Arial;

$ocean-blue: #eff2fb;
$purple: #7b00ff;
$purple-subtle: #e5d4ff;
$status-running: #15cd72;
$saltpan: #f3fae1;
$almost-black: #0d1321;
$auto-metal-saurus: #3e4347;
$teal: #0d492d;
$lime-green: #32ce32;

$custom-colors: (
  "ocean-blue": $ocean-blue,
  "purple": $purple,
  "purple-subtle": $purple-subtle,
  "status-running": $status-running,
  "saltpan": $saltpan,
  "almost-black": $almost-black,
  "auto-metal-saurus": $auto-metal-saurus,
  "teal": $teal,
  "lime-green": $lime-green,
);

$h7-font-size: $font-size-base * 0.75;

$custom-font-sizes: (
  7: $h7-font-size,
);

$theme-colors: map-merge($theme-colors, $custom-colors);
$utilities: map-merge(
  $utilities,
  (
    "color":
      map-merge(
        map-get($utilities, "color"),
        (
          values:
            map-merge(
              map-get(map-get($utilities, "color"), "values"),
              ($theme-colors)
            ),
        )
      ),
  )
);
$body-bg: $light;

$utilities: map-merge(
  $utilities,
  (
    "width": (
      property: width,
      class: w,
      values: (
        15: 15%,
        16: 16%,
        60: 60%,
      ),
    ),
    "font-size": (
      property: font-size,
      class: fs,
      values: $custom-font-sizes,
    ),
    "background-color": (
      property: background-color,
      class: bg,
      values: $theme-colors,
    ),
    "min-viewport-height": (
      property: min-height,
      class: min-vh,
      values: (
        75: 75vh,
        100: 100vh,
      ),
    ),
    "padding-left": (
      property: padding-left,
      class: ps,
      values: (
        6: 6rem,
      ),
    ),
  )
);
$form-range-track-bg: $gray-400;

@import "~bootstrap/scss/bootstrap";
